import * as actionTypes from '../actiontypes/user.actiontype';

const initialState = {
  User : {
    fName : '',
    lName : '',
    email :'',
    password : '',
    reToken : ''

  },
  success: false,
  loading: false,
  error: {
    username: null,
    password: null
  }
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER_DATA: {
      return {
        ...state,
        User : Object.assign({},action.data)
      };
    }
    case actionTypes.ADD_USER_DATA:
        return {
          ...state,
          User : Object.assign({},{
            fName : '',
            lName : '',
            email :'',
            password : '',
            reToken : ''
          })
        };
    case actionTypes.REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case actionTypes.USER_LOGGED_OUT: {
      return state;
    }
    case actionTypes.LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case actionTypes.RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case actionTypes.LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.data
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
