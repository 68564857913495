import history from "history.js";
import axios from 'axios';
import * as actionTypes from '../actiontypes/user.actiontype';
import localStorageService from "../../services/localStorageService";

const ROOT_URL = `${process.env.REACT_APP_API_URL}`;

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    setSession(null);
    setUser(null);
    
    dispatch(setUserData({
      fName: "",
      lName : "",
      email: "",
      password: "",
      reToken : ""
    }));

    history.push({
      pathname: "/session/signin"
    });

    dispatch({
      type: actionTypes.USER_LOGGED_OUT
    });
  };
}

export const addUser = (user) => {
  return (dispatch) => axios.post(`${ROOT_URL}/users/`,
  { 
    fName : user.fName, 
    lName : user.lName, 
    email : user.email, 
    password: user.password,
    reToken : user.reToken,
  })
  .then(response => {
      dispatch({type: actionTypes.ADD_USER_DATA, result: response.data});
      
      history.push({
        pathname: "/session/signin"
      });

      dispatch(setUserData({
        fName: "",
        lName : "",
        email: "",
        password: "",
        reToken : ""
      }));
  })
}


export const loginWithEmailAndPassword = ({ email, password, reToken }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_LOADING
    });
  
    return axios.post(`${ROOT_URL}/auth`, { email : email, password: password, reToken : reToken })
    .then((response)=>{
        dispatch(setUserData(response.data));
        setSession(response.data.token);
        setUser(response.data);
        history.push({
          pathname: "/"
        });

        dispatch(setUserData({
          fName: "",
          lName : "",
          email: "",
          password: "",
          reToken : ""
        }));

        return dispatch({
          type: actionTypes.LOGIN_SUCCESS
        });
    })
    .catch(err=>{
      dispatch(setUserData({
        fName: "",
        lName : "",
        email: "",
        password: "",
        reToken : ""
      }));
      
      return dispatch({
        type: actionTypes.LOGIN_ERROR,
        payload: err
      });
    });
  }
  
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: actionTypes.RESET_PASSWORD
    });
  };
}

export function setSession(token){
  if (token) {
    localStorage.setItem("jwt_token", token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    localStorage.removeItem("jwt_token");
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function setUser(user) {    
  localStorageService.setItem("auth_user", user);
}
// Remove user from localstorage
export function removeUser(){
  localStorage.removeItem("auth_user");
}
