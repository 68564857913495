export const SET_EXPENSE = 'SET_EXPENSE';
export const SET_CATEGORY_OPTIONS = 'SET_CATEGORY_OPTIONS';
export const SET_SUBCATEGORY_OPTIONS = 'SET_SUBCATEGORY_OPTIONS';
export const SET_EXPENSEMODE_OPTIONS = 'SET_EXPENSEMODE_OPTIONS';
export const SET_PAIDVIA_OPTIONS = 'SET_PAIDVIA_OPTIONS';
export const SET_CURRENCY_OPTIONS = 'SET_CURRENCY_OPTIONS';
export const RESET_EXPENSE = 'RESET_EXPENSE';
export const SET_EXPENSE_COLLECTION = 'SET_EXPENSE_COLLECTION';
export const SET_FILE_INFO = 'SET_FILE_INFO';
export const SET_IMAGE_INFO = 'SET_IMAGE_INFO';
export const SHOW_FILE_EXPLORER = 'SHOW_FILE_EXPLORER';
export const RESET_IMAGE_INFO = 'RESET_IMAGE_INFO';
export const SET_WARNINGMODALSTATE = 'SET_WARNINGMODALSTATE';
export const SET_OPEN_DIALOG = 'SET_OPEN_DIALOG';
export const SET_SELECTEDEXPENSEID = 'SET_SELECTEDEXPENSEID';
export const REQUEST_RAISED = 'REQUEST_RAISED';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const SET_FILEEXPLORER_IMAGE = 'SET_FILEEXPLORER_IMAGE';