import { MatxLoadable } from "matx";

const ExpenseForm = MatxLoadable({
  loader: () => import("./Expense")
});


const expenseRoutes = [
  {
    path: "/expenses/manage",
    component: ExpenseForm
  }
];

export default expenseRoutes;