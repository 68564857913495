import * as actionTypes from '../actiontypes/category.actiontype';

const initState = {
    Category : {
        id : '',
        name : '',
        parentCategory : '',
        description : '',
        cLimit : 0,
    },
    selectedCategoryTabId : '',
    categoryOptions : [],
    openConfirmDialog:false,
    selectedCategoryId : '',
    categoryList : []
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_CATEGORY:
      return {
        ...state,
        Category : Object.assign({},{
            id : '',
            name : '',
            description : '',
            cLimit : 0
        }),
        selectedCategoryTabId : '',
        openConfirmDialog:false,
        selectedCategoryId : '',
        selectedSubCategoryId : ''
      };
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        Category : Object.assign({},action.category),
      };
    case actionTypes.SET_CATEGORY_COLLECTION : return {
        ...state,
        categoryList : Object.assign([],action.collection),
        openConfirmDialog:false,
        selectedCategoryId : '',
        selectedSubCategoryId:'',
        selectedCategoryTabId : ''
    }
    case actionTypes.SET_CATEGORY_OPTIONS : return {
      ...state,
      categoryOptions : Object.assign([],action.options)
    }
    case actionTypes.SET_SELECTEDCATEGORYID:
        return {
          ...state,
          selectedCategoryId : action.id,
          selectedSubCategoryId : action.subCategoryId
        };
    case actionTypes.SET_SELECTEDTABID:
        return {
          ...state,
          selectedCategoryTabId : action.selectedTabId
        };
    case actionTypes.SET_OPEN_DIALOG:
      return {
        ...state,
        openConfirmDialog : action.config.openConfirmDialog,
        selectedCategoryId : action.config.selectedCategoryId
      };
    default:
      return state
    }
}