import * as actionTypes from '../actiontypes/file.actiontype';

const initState = {
    File : {
        id:'',
        data : '',
        name : '',
    },
    selectedFileId : '',
    fileList : []
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_FILE:
      return {
        ...state,
        File : Object.assign({},{
            data : '',
            name : '',
        }),
      };
    case actionTypes.RESET_FILE_COLLECTION:
      return {
        ...state,
        fileList : Object.assign({},{}),
      };
    case actionTypes.SET_FILE:
      return {
        ...state,
        File : Object.assign({},action.file),
      };
    case actionTypes.SET_FILE_COLLECTION : return {
        ...state,
        fileList : Object.assign([],action.collection),
    }
    case actionTypes.SET_SELECTEDFILEID:
        return {
          ...state,
          selectedFileId : action.id
        };
    default:
      return state
    }
}