import React, { useEffect } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useSelector, useDispatch,shallowEqual } from 'react-redux';
import { useHistory  } from "react-router-dom";

import { 
  addUser,
  setUserData
} from '../../redux/actions/UserActions';

const SignUp = () => {
  const dispatch = useDispatch();
  const userInStore = useSelector(state => state.user, shallowEqual);
  let history = useHistory();
  const {REACT_APP_SITE_KEY} = process.env;

  useEffect(() => {
      let scriptElem = null;
      const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
    
        if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          scriptElem = document.body.appendChild(script);
        }
    
        if (isScriptExist && callback) callback();
      }
    
      // load the script by passing the URL
      loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${REACT_APP_SITE_KEY}`, function () {
        console.log("Script loaded!");
      });
      
      return function cleanup() {
          dispatch(setUserData({
            fName: "",
            lName : "",
            email: "",
            password: "",
            reToken:""
          }));
          document.body.removeChild(scriptElem);
      }; 
  },[dispatch]);

  function handleChange (event) {
    const {User} = userInStore;
    User[event.target.name] = event.target.value;
    dispatch(setUserData(User));
  }

  function handleSubmit (event) {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(`${REACT_APP_SITE_KEY}`, { action: 'submit' }).then(token => {
        const {User} = userInStore;
        User.permissionLevel = 1; //we can move this code server side
        User['reToken'] = token;
        dispatch(addUser(User));
      });
    });
  }

  return(
    <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                  <img
                    src="/assets/images/illustrations/posting_photo.svg"
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100">
                  <ValidatorForm onSubmit={(event)=>handleSubmit(event)}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="First Name"
                      onChange={(event)=>handleChange(event)}
                      type="text"
                      name="fName"
                      value={userInStore.User.fName}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Last Name"
                      onChange={(event)=>handleChange(event)}
                      type="text"
                      name="lName"
                      value={userInStore.User.lName}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={(event)=>handleChange(event)}
                      type="email"
                      name="email"
                      value={userInStore.User.email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid"
                      ]}
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Password"
                      variant="outlined"
                      onChange={(event)=>handleChange(event)}
                      name="password"
                      type="password"
                      value={userInStore.User.password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <FormControlLabel
                      className="mb-16"
                      name="agreement"
                      onChange={(event)=>handleChange(event)}
                      control={<Checkbox />}
                      label="I have read and agree to the terms of service."
                    />

                    <div className="flex flex-middle">
                      <Button
                        className="capitalize"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Sign up
                      </Button>
                      <span className="ml-16 mr-8">or</span>
                      <Button
                        className="capitalize"
                        onClick={() =>
                          history.push("/session/signin")
                        }
                      >
                        Sign in
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
  );
}


export default SignUp;