import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "history.js";
import * as actionTypes from '../actiontypes/user.actiontype';
import localStorageService from '../../services/localStorageService';

export function loginWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: actionTypes.LOGIN_LOADING
    });

    jwtAuthService
      .loginWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(setUserData(user));

        history.push({
          pathname: "/"
        });

        return dispatch({
          type: actionTypes.LOGIN_SUCCESS
        });
      })
      .catch(error => {
        return dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: actionTypes.RESET_PASSWORD
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(user => {
        if (user) {
          dispatch(
            setUserData(localStorageService.getItem('auth_user'))
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: actionTypes.LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: actionTypes.LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: error
        });
      });
  };
}
