import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import BuddyReducer from './BuddyReducer';
import CurrencyReducer from './CurrencyReducer';
import CategoryReducer from './CategoryReducer';
import ExpenseModeReducer from './ExpenseModeReducer';
import ExpenseTypeReducer from './ExpenseTypeReducer';
import ExpenseReducer from './ExpenseReducer';
import FileReducer from './FileReducer'

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  buddy : BuddyReducer,
  currency : CurrencyReducer,
  category : CategoryReducer,
  expensemode : ExpenseModeReducer,
  expensetype : ExpenseTypeReducer,
  expense : ExpenseReducer,
  file : FileReducer
});

export default RootReducer;
