import React, { useEffect } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useSelector, useDispatch,shallowEqual } from 'react-redux';
import { setUserData, loginWithEmailAndPassword } from "../../redux/actions/UserActions";
import { useHistory  } from "react-router-dom";

const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const SignIn = () => {
  const dispatch = useDispatch();
  const userInStore = useSelector(state => state.user, shallowEqual);
  const {REACT_APP_SITE_KEY} = process.env;
  const classes = styles();
  let history = useHistory();
  let scriptElem = null;
  
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        scriptElem = document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${REACT_APP_SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
    return function cleanup() {
        dispatch(setUserData({
          fName: "",
          lName : "",
          email: "",
          password: "",
          reToken : ""
        }));
        
        document.body.removeChild(scriptElem);
    }; 
  },[dispatch]);

  function handleChange (event) {
    const {User} = userInStore;
    User[event.target.name] = event.target.value;
    dispatch(setUserData(User));
  }

  function handleSubmit (event) {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(`${REACT_APP_SITE_KEY}`, { action: 'submit' }).then(token => {
        const {User} = userInStore;
        User["reToken"] = token;
        dispatch(loginWithEmailAndPassword(User));
      });
    });
  }

  return(
      <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle h-100">
                <img src="/assets/images/illustrations/dreamer.svg" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <ValidatorForm onSubmit={(event)=>handleSubmit(event)}>
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    onChange={(event)=>handleChange(event)}
                    type="email"
                    name="email"
                    value={userInStore.User && userInStore.User.email ? userInStore.User.email : ""}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid"
                    ]}
                  />
                  <TextValidator
                    className="mb-16 w-100"
                    label="Password"
                    variant="outlined"
                    onChange={(event)=>handleChange(event)}
                    name="password"
                    type="password"
                    value={userInStore.User && userInStore.User.password ? userInStore.User.password : ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <FormControlLabel
                    className="mb-8"
                    name="agreement"
                    onChange={(event)=>handleChange(event)}
                    control={<Checkbox checked />}
                    label="I have read and agree to the terms of service."
                  />

                

                  <div className="flex flex-middle mb-8">
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={userInStore.loading}
                        type="submit"
                      >
                        Sign in to Enter Dashboard
                      </Button>
                      {userInStore.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <span className="ml-16 mr-8">or</span>
                    <Button
                      className="capitalize"
                      onClick={() =>
                        {
                          history.push("/session/signup")
                        }
                      }
                    >
                      Sign up
                    </Button>
                  </div>
                  <Button
                    className="text-primary"
                    onClick={() =>
                      history.push("/session/forgot-password")
                    }
                  >
                    Forgot password?
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default SignIn;