import * as actionTypes from '../actiontypes/expense.actiontype';

const initState = {
    Expense : {
        id : '',
        title : '',
        eDate : new Date(),
        category : '',
        subcategory : '',
        amount : 0,
        expenseMode : '',
        currency : '',
        paymentMode : '',
        shareWithBuddy : false,
        buddy : [],
        description : '',
        createdBy : '',
        createdById : '',
        modifiedBy : '',
        modifiedById : '',
        owner : '',
        ownerId : '',
        billImage : '',
        selectedLocation : ''
    },
    myFiles : [],
    selectedExpenseId : '',
    openConfirmDialog : false,
    expenseList : [],
    categoryOptions : [],
    subCategoryOptions : [],
    paidViaOptions : [],
    expenseTypeOptions : [],
    currencyOptions : [],
    numOfFiles : 0,
    fileName : '',
    selectedFile : null,
    showImageViewer : false,
    showFileExplorer : false,
    selectedExpense : null,
    fileExplorerSelectedImage : '',
    fileExplorerSelectedImageData : ''
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_EXPENSE:
      return {
        ...state,
        Expense : Object.assign({},{
          id : '',
          title : '',
          eDate : new Date(),
          category : '',
          subcategory : '',
          amount : 0,
          expenseMode : '',
          currency : '',
          paymentMode : '',
          shareWithBuddy : false,
          buddy : [],
          description : '',
          createdBy : '',
          createdById : '',
          modifiedBy : '',
          modifiedById : '',
          owner : '',
          ownerId : '',
          billImage : '',
          selectedLocation : ''
        }),
      };
    case actionTypes.SET_EXPENSE:
      return {
        ...state,
        Expense : Object.assign({},action.expense),
      };
    case actionTypes.SET_IMAGE_INFO:
      return {
        ...state,
        selectedExpense : Object.assign({},action.expenseDetails.expense),
        showImageViewer : action.expenseDetails.showImageViewer
      };
    case actionTypes.RESET_IMAGE_INFO:
      return {
        ...state,
        Expense : Object.assign({},Object.assign({}, {})),
        showImageViewer : false
      };
    case actionTypes.SHOW_FILE_EXPLORER :  return {
      ...state,
      showFileExplorer : action.payload
    };
    case actionTypes.SET_FILE_INFO:
      return {
        ...state,
        numOfFiles : action.fileInfo.numOfFiles,
        fileName : action.fileInfo.fileName,
        selectedFile : action.fileInfo.selectedFile
      };
    case actionTypes.SET_EXPENSE_COLLECTION : return {
        ...state,
        expenseList : Object.assign([],action.collection),
        openConfirmDialog : false,
        selectedExpenseId : '',
        numOfFiles : 0,
        fileName : '',
        selectedFile : null
    };
    case actionTypes.SET_CATEGORY_OPTIONS : return {
      ...state,
      categoryOptions : Object.assign([],action.collection),
    };
    case actionTypes.SET_SUBCATEGORY_OPTIONS : return {
      ...state,
      subCategoryOptions : Object.assign([],action.collection),
    };
    case actionTypes.SET_CURRENCY_OPTIONS : return {
      ...state,
      currencyOptions : Object.assign([],action.collection),
    };
    case actionTypes.SET_EXPENSEMODE_OPTIONS : return {
      ...state,
      expenseTypeOptions : Object.assign([],action.collection),
    };
    case actionTypes.SET_PAIDVIA_OPTIONS : return {
      ...state,
      paidViaOptions : Object.assign([],action.collection),
    };
    case actionTypes.SET_SELECTEDEXPENSEID:
        return {
          ...state,
          selectedExpenseId : action.id
        };
    case actionTypes.SET_FILEEXPLORER_IMAGE : 
        return{
          ...state,
          fileExplorerSelectedImage : action.payload.fileId,
          fileExplorerSelectedImageData : action.payload.fileData
        };
    case actionTypes.SET_OPEN_DIALOG:
      return {
        ...state,
        openConfirmDialog : action.config.openConfirmDialog,
        selectedExpenseId : action.config.selectedExpenseId
      };
    default:
      return state
    };
}