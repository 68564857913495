import { MatxLoadable } from "matx";

const CurrencyForm = MatxLoadable({
  loader: () => import("./Currency")
});

const CategoryForm = MatxLoadable({
    loader: () => import("./Category")
});

const ExpenseModeForm = MatxLoadable({
    loader: () => import("./ExpenseMode")
});

const ExpenseTypeForm = MatxLoadable({
    loader: () => import("./ExpenseType")
});

const masterRoutes = [
  {
    path: "/masters/currency",
    component: CurrencyForm
  },
  {
    path: "/masters/category",
    component: CategoryForm
  },
  {
    path: "/masters/expensemode",
    component: ExpenseModeForm
  },
  {
    path: "/masters/paymenttype",
    component: ExpenseTypeForm
  }
];

export default masterRoutes;