import * as actionTypes from '../actiontypes/currency.actiontype';

const initState = {
    Currency : {
        id : '',
        title : '',
        symbol : '',
        setAsDefault : false
    },
    selectedCurrencyId : '',
    currencyList : [],
    openConfirmDialog : false,
    showSnackBar : false,
    requestRaised : false
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_CURRENCY:
      return {
        ...state,
        Currency : Object.assign({},{
            id : '',
            title : '',
            symbol : '',
            setAsDefault : false
        }),
      };
    case actionTypes.SET_CURRENCY:
      return {
        ...state,
        Currency : Object.assign({},action.currency),
      };
    case actionTypes.SET_CURRENCY_COLLECTION : return {
        ...state,
        currencyList : Object.assign([],action.collection),
        Currency : Object.assign({},{
            id : '',
            title : '',
            symbol : '',
            setAsDefault : false
        }),
        selectedCurrencyId : '',
        openConfirmDialog : false,
        //showSnackBar : false
    }
    case actionTypes.SET_SELECTEDCURRENCYID:
        return {
          ...state,
          selectedCurrencyId : action.id
        };
    case actionTypes.SET_OPEN_DIALOG:
      return {
        ...state,
        openConfirmDialog : action.config.openConfirmDialog,
        selectedCurrencyId : action.config.selectedCurrencyId
      };
    case actionTypes.SET_SNACKBAR:
        return {
          ...state,
          showSnackBar : action.showSnackBar
        };
    default:
      return state
    }
}