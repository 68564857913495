export const SET_CATEGORY = 'SET_CATEGORY';
export const RESET_CATEGORY = 'RESET_CATEGORY';
export const SET_CATEGORY_COLLECTION = 'SET_CATEGORY_COLLECTION';
export const SET_CATEGORY_OPTIONS = 'SET_CATEGORY_OPTIONS';
export const SET_WARNINGMODALSTATE = 'SET_WARNINGMODALSTATE';
export const SET_SELECTEDCATEGORYID = 'SET_SELECTEDCATEGORYID';
export const SET_SELECTEDTABID = 'SET_SELECTEDTABID';
export const SET_OPEN_DIALOG = 'SET_OPEN_DIALOG';
export const REQUEST_RAISED = 'REQUEST_RAISED';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REQUEST_FAILED';