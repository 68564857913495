import { MatxLoadable } from "matx";

const BuddyForm = MatxLoadable({
  loader: () => import("./Buddy")
});

const buddyRoutes = [
  {
    path: "/buddies/manage",
    component: BuddyForm
  }
];

export default buddyRoutes;