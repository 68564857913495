import * as actionTypes from '../actiontypes/buddy.actiontype';

const initState = {
    Buddy : {
        id : '',
        fName : '',
        lName : '',
        email : '',
        oBalance : 0,
        cLimit : 0,
        bDate : new Date(),
        mNumber : '',
        gender : '',
        numOfFiles : 0,
        fileName : '',
        selectedFile : null
    },
    selectedBuddyId : '',
    buddyList : [],
    openConfirmDialog:false
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_BUDDY:
      return {
        ...state,
        Buddy : Object.assign({},{
            id : '',
            fName : '',
            lName : '',
            email : '',
            oBalance : 0,
            cLimit : 0,
            bDate : new Date(),
            mNumber : ''
        }),
      };
    case actionTypes.SET_BUDDY:
      return {
        ...state,
        Buddy : Object.assign({},action.buddy),
      };
    case actionTypes.SET_BUDDY_COLLECTION : return {
        ...state,
        buddyList : Object.assign([],action.collection),
        selectedBuddyId : '',
        openConfirmDialog:false
    }
    case actionTypes.SET_SELECTEDBUDDYID:
        return {
          ...state,
          selectedBuddyId : action.id
        };
    case actionTypes.SET_OPEN_DIALOG:
      return {
        ...state,
        openConfirmDialog : action.config.openConfirmDialog,
        selectedBuddyId : action.config.selectedBuddyId
      };
    default:
      return state
    }
}