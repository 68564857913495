import * as actionTypes from '../actiontypes/expensetype.actiontype';

const initState = {
    ExpenseType : {
        id : '',
        name : '',
        description : ''
    },
    selectedExpenseTypeId : '',
    openConfirmDialog : false,
    expenseTypeList : []
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_EXPENSETYPE:
      return {
        ...state,
        ExpenseType : Object.assign({},{
            id : '',
            name : '',
            description : ''
        }),
      };
    case actionTypes.SET_EXPENSETYPE:
      return {
        ...state,
        ExpenseType : Object.assign({},action.expensetype),
      };
    case actionTypes.SET_EXPENSETYPE_COLLECTION : return {
        ...state,
        expenseTypeList : Object.assign([],action.collection),
        selectedExpenseTypeId : '',
        openConfirmDialog : false,
    }
    case actionTypes.SET_SELECTEDEXPENSETYPEID:
        return {
          ...state,
          selectedExpenseTypeId : action.id
        };
    case actionTypes.SET_OPEN_DIALOG:
        return {
          ...state,
          openConfirmDialog : action.config.openConfirmDialog,
          selectedExpenseTypeId : action.config.selectedExpenseTypeId
        };
    default:
      return state
    }
}