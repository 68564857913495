import * as actionTypes from '../actiontypes/expensemode.actiontype';

const initState = {
    ExpenseMode : {
        id : '',
        name : '',
        description : ''
    },
    selectedExpenseModeId : '',
    expenseModeList : [],
    openConfirmDialog : false
}

export default (state = initState, action) => {
    switch (action.type) {
    case actionTypes.REQUEST_RAISED:
        return {
          ...state,
          requestRaised : true
        };
    case actionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          requestRaised : false
        };
    case actionTypes.REQUEST_FAILED:
        return {
          ...state,
          requestRaised : false,
          err : action.err
        }
    case actionTypes.RESET_EXPENSEMODE:
      return {
        ...state,
        ExpenseMode : Object.assign({},{
            id : '',
            name : '',
            description : ''
        }),
      };
    case actionTypes.SET_EXPENSEMODE:
      return {
        ...state,
        ExpenseMode : Object.assign({},action.expensemode),
      };
    case actionTypes.SET_EXPENSEMODE_COLLECTION : return {
        ...state,
        expenseModeList : Object.assign([],action.collection),
        selectedExpenseModeId : '',
        openConfirmDialog : false
    }
    case actionTypes.SET_SELECTEDEXPENSEMODEID:
        return {
          ...state,
          selectedExpenseModeId : action.id
        };
    case actionTypes.SET_OPEN_DIALOG:
      return {
        ...state,
        openConfirmDialog : action.config.openConfirmDialog,
        selectedExpenseModeId : action.config.selectedExpenseModeId
      };
    default:
      return state
    }
}